export const customGetInteraProfile = () => import('./actions');
export const customPostInteraProfileRecharge = () => import('./actions');
export const customPostInteraProfileTransfer = () => import('./actions');
export const getPostalCodeLocation = () => import('./get-postal-code-location');
export const getSubscriptions = () => import('./get-subscriptions');
export const getBillingReceipts = () => import('./get-billing-receipts');
export const getSecondCopyOfInvoice = () => import('./get-second-copy-invoice');
export const putSubscription = () => import('./update-subscription');
export const putSubscriptionSamePayment = () => import('./update-subscription');
export const getTreatments = () => import('./get-treatments');
export const putTreatments = () => import('./update-treatments');
export const customGetOrder = () => import('./order-details');
